
<template>
  <div class="CommunityUser" :data-content="member.points" :class="{'hideGamification':hideGamification}" ref="communityUserWidth">
    <div class="CommunityUser--reduced" v-if="reduced">
      <div class="CommunityUser--delete" v-if="allowDelete && reduced">
        <svg v-on:click="$emit('delete')" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
          <path d="M13.4962 13.6241L9.29099 9.41893L13.4962 5.21376C13.5762 5.13368 13.5762 5.00383 13.4962 4.92375C13.4161 4.84367 13.2862 4.84367 13.2061 4.92375L9.00098 9.12892L4.79581 4.92375C4.71573 4.84367 4.58588 4.84367 4.5058 4.92375C4.42572 5.00383 4.42572 5.13368 4.5058 5.21376L8.71097 9.41893L4.5058 13.6241C4.42572 13.7042 4.42572 13.834 4.5058 13.9141C4.58588 13.9942 4.71573 13.9942 4.79581 13.9141L9.00098 9.70894L13.2061 13.9141C13.2862 13.9942 13.4161 13.9942 13.4962 13.9141C13.5762 13.834 13.5762 13.7042 13.4962 13.6241Z" fill="#E8060F" stroke="#E8060F"/>
          </g>
          <defs>
          <clipPath id="clip0">
          <rect width="12.7143" height="12.7143" fill="white" transform="translate(9.00098 0.428574) rotate(45)"/>
          </clipPath>
          </defs>
        </svg>
      </div>
      <h1>{{member.nickname}}</h1>
    </div>
    <div v-if="!reduced" class="CommunityUser--Content">
      <!-- <div class="CommunityUser--image" :style="{ backgroundImage: 'url(\'' + member.picture + '\')' }">
      </div> -->
      <Avatar class="CommunityUser--image" :src="member.picture" :allowLabs="false" :online="member.online"/>
      <div class="CommunityUser--info">
        <h1>{{member.nickname}}</h1>
        <div class="CommunityUser--segmentation">
          <p v-if="member.age">{{$t('age',{count:member.age})}}</p>
          <span v-if="member.gender"></span>
          <p v-if="member.gender">{{$t('gender_'+member.gender)}}</p>
          <span v-if="(member.province && member.province.text) || member.geographicZone"></span>
          <p v-if="member.province && member.province.text">{{$t(''+member.province.text)}}</p>
          <p v-if="member.geographicZone && (!member.province || member.province.text == undefined || member.province.text == null)">{{member.geographicZone}}</p>
        </div>
        <div class="CommunityUser--segmentation" v-show="member.activityParticipationsNumber">
          {{member.activityParticipationsNumber}} {{$t('ranking_page_participations')}}
        </div>
        <div class="CommunityUser--Tags" ref="tagsContainer">
          <Tag :name="t" class="TagV2" v-for="t in allTags"/>
        </div>
        <div class="CommunityUser--Bottom">
          <div class="CommunityUser--Action">
            <svg v-on:click="$emit('add')" v-if="allowAdd" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M13.7742 6.77419H7.22581V0.225806C7.22581 0.101105 7.1247 0 7 0C6.8753 0 6.77419 0.101105 6.77419 0.225806V6.77419H0.225806C0.101105 6.77419 0 6.8753 0 7C0 7.1247 0.101105 7.22581 0.225806 7.22581H6.77419V13.7742C6.77419 13.8989 6.8753 14 7 14C7.1247 14 7.22581 13.8989 7.22581 13.7742V7.22581H13.7742C13.8989 7.22581 14 7.1247 14 7C14 6.8753 13.8989 6.77419 13.7742 6.77419Z" fill="#96C800" stroke="#96C800" stroke-width="0.7"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="14" height="14" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <svg v-on:click="$emit('delete')" v-if="allowDelete" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M12.2691 1.43351H9.6308V0.46602C9.6308 0.20867 9.41516 0 9.14916 0H5.62132C5.35534 0 5.13968 0.208643 5.13968 0.46602V1.43351H2.50142C2.23544 1.43351 2.01978 1.64215 2.01978 1.89953V3.33301C2.01978 3.59036 2.23541 3.79903 2.50142 3.79903H2.64773L3.08795 13.8447C3.09885 14.0942 3.31108 14.291 3.56912 14.291H11.2013C11.4594 14.291 11.6716 14.0942 11.6825 13.8447L12.1227 3.799H12.269C12.535 3.799 12.7506 3.59036 12.7506 3.33298V1.89953C12.7507 1.64212 12.5351 1.43351 12.2691 1.43351ZM6.10296 0.932011H8.66751V1.43351H6.10296V0.932011ZM10.7397 13.359H4.03077L3.61185 3.79903H11.1586L10.7397 13.359ZM11.7874 2.86699C11.3498 2.86699 3.24522 2.86699 2.98306 2.86699V2.36552H11.7874V2.86699Z" fill="#BDBDBD"/>
              <path d="M5.44746 5.22803C5.18149 5.22803 4.96582 5.43667 4.96582 5.69405V11.4639C4.96582 11.7212 5.18146 11.9299 5.44746 11.9299C5.71344 11.9299 5.9291 11.7212 5.9291 11.4639V5.69405C5.92907 5.43667 5.71344 5.22803 5.44746 5.22803Z" fill="#BDBDBD"/>
              <path d="M7.38496 5.22803C7.11899 5.22803 6.90332 5.43667 6.90332 5.69405V11.4639C6.90332 11.7212 7.11893 11.9299 7.38496 11.9299C7.65094 11.9299 7.8666 11.7212 7.8666 11.4639V5.69405C7.86657 5.43667 7.65094 5.22803 7.38496 5.22803Z" fill="#BDBDBD"/>
              <path d="M9.32246 5.22803C9.05649 5.22803 8.84082 5.43667 8.84082 5.69405V11.4639C8.84082 11.7212 9.05646 11.9299 9.32246 11.9299C9.58847 11.9299 9.8041 11.7212 9.8041 11.4639V5.69405C9.8041 5.43667 9.58847 5.22803 9.32246 5.22803Z" fill="#BDBDBD"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="14.77" height="14.291" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="CommunityUser--Remind" v-if="allowRemind && !member.hastParticipated" v-on:click="$emit('remind-member', member)">
          <i class="fas fa-paper-plane"></i>{{$t('send_reminder')}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tag from '~/components/Tag'
import Avatar from '~/components/Avatar';
export default {
  name:'CommunityUser',
  components:{
    Tag,
    Avatar
  },
  props: {
    member:Object,
    allowAdd:{
      type: Boolean,
      default: false,
    },
    allowDelete:{
      type: Boolean,
      default: false,
    },
    allowDelete:{
      type: Boolean,
      default: false,
    },
    allowTags:{
      type: Boolean,
      default: false,
    },
    allowPublicTags:{
      type: Boolean,
      default: false,
    },
    allowRemind:{
      type: Boolean,
      default: true
    },
    hideGamification:{
      type: Boolean,
      default: false,
    },
    reduced:{
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    showTagsEllipsis: false
  }),

  computed: {
    allTags() {
      if (this.member.tags != undefined) {
        return this.member.tags.concat(this.member.publicTags);
      }
    }
  }

}
</script>
<style scoped lang="scss">
.CommunityUser{
  background: #F5F5F5;
  padding: 9px;
  position: relative;
  min-width: 220px;
  h1{
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: #3E3E3E;
  }
  &.hideGamification{
    &::Before{
      display:none;
    }
  }
  &::before{
    content:attr(data-content);
    width: 46px;
    color: white;
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    border: 1px solid #FFFFFF;
    box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.11);
    position: absolute;
    right: -5px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  &--reduced{
    display: flex;
    align-items: center;
  }
  &--info{
    width: 100%;
  }
  &--Content{
    display: flex;
  }
  &--delete{
    background: white;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  &--image{
    width: 43px;
    min-width: 43px;
    height: 43px;
    background: white;
    margin-right: 12px;
    -webkit-print-color-adjust: exact;
    box-shadow: none;
  }
  &--segmentation{
    display: flex;
    font-size: 13px;
    color: #747474;
    width: max-content;
    span{
      background: #747474;
      width: 1px;
      margin: 2px 5px;
    }
  }
  &--Bottom{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &--Tags{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .TagV2 {
    margin-right: 5px;
  }
  &--Action{
    cursor: pointer;
  }
  &--Remind{
    color: #f6c07e;
    text-transform: uppercase;
    font-size: 0.7em;
    margin-top: 5px;
    cursor: pointer;
    i{
      margin-right: 5px;
    }
  }
}
</style>
